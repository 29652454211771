import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { hardCodedData } from "../assets/hardCodedData";
import * as XLSX from "xlsx";
// import { Dot } from "react-animated-dots";
import { axiosInstance } from "./axiosInstance";
import axios from "axios";
import { useAuth } from "../components/Auth/useAuth";

export default function GenerateReport({ InputObject, RequestFilters }) {
  const [provinceFilter, setProvinceFilter] = useState("");
  const [cropyearFilter, setCropyearFilter] = useState("");
  const [cropFilter, setCropFilter] = useState("");
  const { user } = useAuth();
  const [cropyearOptions, setCropyearOptions] = useState([]);
  const [cropOptions, setCropOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [provinceOptions, setProvinceOptions] = useState(
    Object.keys(RequestFilters)
  );

  useEffect(() => {
    setProvinceOptions(Object.keys(RequestFilters));
  }, [RequestFilters, provinceFilter, cropyearFilter, cropFilter]);

  // async function handleGenerateReport() {
  //   const key = provinceFilter + cropyearFilter + cropFilter;

  //   console.log(InputObject[key]);
  //   setLoading(true);

  //   const response = await axiosInstance.post(
  //     "/partners/analysisPDF",
  //     {
  //       input_rows: InputObject[key],
  //     },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + user.token,
  //       },
  //     }
  //   );
  //   handleExportToCSV(response.data);
  //   setLoading(false);
  // }

  async function handleGenerateReport() {
    const key = provinceFilter + cropyearFilter + cropFilter;
    console.log(InputObject[key]);
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/partners/analysisPDF",
        {
          input_rows: InputObject[key],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      );

      handleExportToCSV(response.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Show an alert with the error message for 403 errors
        alert(
          error.response.data.detail ||
            "You have exceeded your free quota for report generation."
        );
      } else {
        // Handle other errors
        alert(
          "An error occurred while generating the report. Please try again."
        );
      }
    } finally {
      setLoading(false);
    }
  }

  function handleExportToCSV(data) {
    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a CSV string
    const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

    // Create a Blob from the CSV string
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

    // Create a hidden anchor element and trigger the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute(
      "download",
      `results_${provinceFilter}_${cropyearFilter}_${cropFilter
        .split(" ")
        .join("")}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Box
      className="w-full h-full"
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <div className="w-full h-full flex flex-col items-center">
        <TextField
          value={provinceFilter}
          select
          label="Select"
          helperText="1.Please select a canadian province"
          onChange={(event) => {
            setProvinceFilter(event.target.value);
            setCropyearFilter("");
            setCropFilter("");

            let tmpCropyears = new Set();
            let tmpCrops = new Set();

            for (const cy in RequestFilters[event.target.value]) {
              tmpCropyears.add(cy);
              for (const crp in RequestFilters[event.target.value][cy]) {
                tmpCrops.add(crp);
              }
            }

            setCropyearOptions([...tmpCropyears].sort());
            setCropOptions([...tmpCrops].sort());
          }}
        >
          {provinceOptions.map((province) => (
            <MenuItem key={province} value={province}>
              {province}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          value={cropyearFilter}
          label="Select"
          helperText="2.Please select a year"
          disabled={provinceFilter === ""}
          onChange={(event) => {
            setCropyearFilter(event.target.value);
            setCropFilter("");

            let tmpCrops = new Set();
            let selectedCY = event.target.value;
            for (const crp in RequestFilters[provinceFilter][selectedCY]) {
              //   console.log(crp);
              tmpCrops.add(crp);
            }
            console.log([...tmpCrops].sort());
            setCropOptions([...tmpCrops].sort());
          }}
        >
          {cropyearOptions.map((crop_year) => (
            <MenuItem key={crop_year} value={crop_year}>
              {crop_year}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          value={cropFilter}
          label="Select"
          helperText="3.Please select a crop"
          disabled={provinceFilter === "" || cropyearFilter === ""}
          onChange={(event) => {
            setCropFilter(event.target.value);
          }}
        >
          {cropOptions.map((crop) => (
            <MenuItem key={crop} value={crop}>
              {crop}
            </MenuItem>
          ))}
        </TextField>

        <Button
          variant="contained"
          onClick={handleGenerateReport}
          sx={{ marginTop: "16px" }}
          disabled={
            provinceFilter === "" || cropyearFilter === "" || cropFilter === ""
          }
        >
          {isLoading && (
            <CircularProgress size="1rem" sx={{ color: "white", mr: "1rem" }} />
          )}
          {isLoading && <p>Computing</p>}
          {!isLoading && "Download Results"}
        </Button>
      </div>
    </Box>
  );
}
